import { createRoot } from 'react-dom/client';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

import CookiesPolicy from './components/cookies_policy';

const { showDefaultMessage } = window.ModalHelper;

const CookiesPolicyApp = () => {
  if (bvVar('cookiesPolicyModal')) {
    showDefaultMessage({
      closeOnOverlayClick: false,
      title: t('cookies_disclaimer_title'),
      className: 'cookies-disclaimer is-info',
      icon: true,
      close: true,
      children: <CookiesPolicy isModal />,
    });
  } else {
    let domEl;

    const getDomEl = () => {
      if (domEl) return domEl;

      domEl = document.createElement('div');
      document.body.appendChild(domEl);
      return domEl;
    };

    const root = createRoot(getDomEl());
    root.render(<CookiesPolicy onClose={() => root.unmount()} />);
  }
};

export default CookiesPolicyApp;
