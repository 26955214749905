import { Component } from 'react';
import PropTypes from 'prop-types';
import { getJSON } from 'bv-fetch';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import { acceptCookiesPolicy } from '../helper';

const { showDefaultMessage } = window.ModalHelper;

export default class CookiesPolicy extends Component {
  openCookiePolicy = () => {
    getJSON('pages/by_content_tag/cookies_policy').then((page) => {
      if (page) {
        showDefaultMessage({
          title: t('javascript.registration_path.terms_conditions.title'),
          iconClosable: true,
          html: page.body,
          className: 'cookies-modal',
          actions: [{
            label: t('javascript.cookie_policy.agree'),
            secondary: true,
            remove: true,
            close: true,
            onClick: this.agreePolicy,
          }],
        });
      }
    });
  };

  agreePolicy = () => {
    acceptCookiesPolicy();
    const { isModal, onClose } = this.props;
    if (isModal) {
      window.reduxState.store.actionDispatchers.modal.removeModal();
    }
    onClose();
  };

  render() {
    const { isModal } = this.props;
    const className = isModal ? 'cookies-policy--modal' : 'cookies-policy--flat';
    return (
      <div className={`cookies-policy ${className}`}>
        <div className="cookies-policy__message">
          <p className="cookies-policy__par">{t('cookies_disclaimer_1')}</p>
          {' '}
          <p className="cookies-policy__par">
            {t('cookies_disclaimer_2')}
            {' '}
            <span className="lm-link bvs-link cookies-policy__link" onClick={this.openCookiePolicy}>
              {t('javascript.cookie_policy.link')}
            </span>
          </p>
        </div>
        <div className="bvs-button-group cookies-policy__controls">
          <Button
            className="close cookies-policy__btn"
            label={t('javascript.cookie_policy.agree')}
            onClick={this.agreePolicy}
            secondary
          />
        </div>
      </div>
    );
  }
}

CookiesPolicy.propTypes = {
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
};

CookiesPolicy.defaultProps = {
  isModal: false,
  onClose: () => {},
};
